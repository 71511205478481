import * as React from "react";

import Layout from "@layouts/Layout";
import Seo from "@components/Seo";
import { Typography } from "@components/atoms";

import info from "@constants/branding";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Seo title="Privacy Policy" />
      <Typography align="center" variant="h1">
        Privacy Policy
      </Typography>
      <Typography variant="body">
        {`At ${info.domain}, we recognize that privacy of your personal
        information is important. Here is information on what types of personal
        information we receive and collect when you use and visit
        ${info.domain}, and how we safeguard your information. We never sell
        your personal information to third parties.`}
      </Typography>
      <Typography variant="h3">Who We Are</Typography>
      <Typography variant="body">
        {`We are ${info.title} and our website address is
        https://www.${info.domain}. We can be contacted at
        info@${info.domain}.`}
      </Typography>
      <Typography variant="h3">
        What Personal Data We Collect and Why We Collect It
      </Typography>
      <Typography variant="body">
        When visitors leave comments on the site we collect the data shown in
        the comments form, and also the visitor’s IP address and browser user
        agent string to help spam detection.
      </Typography>
      <Typography variant="body">
        An anonymized string created from your email address (also called a
        hash) may be provided to the Gravatar service to see if you are using
        it. The Gravatar service privacy policy is available here:
        https://automattic.com/privacy/. After approval of your comment, your
        profile picture is visible to the public in the context of your comment.
      </Typography>
      <Typography variant="body">
        We do not collect your email address from the comments form for use in
        any email list, such as a newsletter or marketing email list. We also
        never sell email addresses to third parties.
      </Typography>
      <Typography variant="body">
        In general, users are not able to upload images or other media files to
        this website. However, if you do upload images to the website, you
        should avoid uploading images with embedded location data (EXIF GPS)
        included. Visitors to the website can download and extract any location
        data from images on the website.
      </Typography>
      <Typography variant="body">
        {`When you fill out a contact form on ${info.domain} we only collect the
        information you enter into the contact form. If the form asks for your
        name, email address, or any other personal information, then that
        information is sent to us via email. We only retain that
        information—including your email address—for as long as necessary to
        address your purpose in contacting us.`}
      </Typography>
      <Typography variant="body">
        Email addresses provided in contact forms are not ever used by us for
        any purpose other than to reply to you in regards to your reason for
        contacting us. We never sell information from contact forms to any third
        party for any purpose.
      </Typography>
      <Typography variant="body">
        As with most other websites, we collect and use the data contained in
        log files. The information in the log files includes your IP (internet
        protocol) address, your ISP (internet service provider, such as AOL or
        Shaw Cable), the browser you used to visit our site (such as Internet
        Explorer or Firefox), the time you visited our site and which pages you
        visited throughout our site.
      </Typography>
      <Typography variant="h3">Advertising</Typography>
      <Typography variant="body">
        {`We use third-party advertisements on ${info.domain} to support our site.
        Some of these advertisers may use technology such as cookies and web
        beacons when they advertise on our site, which will also send these
        advertisers (such as Google through the Google AdSense program)
        information including your IP address, your ISP, the browser you used to
        visit our site, and in some cases, whether you have Flash installed.
        This is generally used for geotargeting purposes (showing New York real
        estate ads to someone in New York, for example) or showing certain ads
        based on specific sites visited (such as showing cooking ads to someone
        who frequents cooking sites).`}
      </Typography>
      <Typography variant="body">
        You can choose to disable or selectively turn off our cookies or
        third-party cookies in your browser settings, or by managing preferences
        in programs such as Norton Internet Security. However, this can affect
        how you are able to interact with our site as well as other websites.
        This could include the inability to login to services or programs, such
        as logging into forums or accounts.
      </Typography>
      <Typography variant="body">
        In addition, if you leave a comment on our site you may opt-in to saving
        your name, email address and website in cookies. These are for your
        convenience so that you do not have to fill in your details again when
        you leave another comment. These cookies will last for one year.
      </Typography>
      <Typography variant="body">
        If you have an account and you log in to this site, we will set a
        temporary cookie to determine if your browser accepts cookies. This
        cookie contains no personal data and is discarded when you close your
        browser.
      </Typography>
      <Typography variant="body">
        When you log in, we will also set up several cookies to save your login
        information and your screen display choices. Login cookies last for two
        days, and screen options cookies last for a year. If you select
        “Remember Me”, your login will persist for two weeks. If you log out of
        your account, the login cookies will be removed.
      </Typography>
      <Typography variant="body">
        If you edit or publish an article, an additional cookie will be saved in
        your browser. This cookie includes no personal data and simply indicates
        the post ID of the article you just edited. It expires after 1 day.
      </Typography>
      <Typography variant="h3">Embedded Content From Other Websites</Typography>
      <Typography variant="body">
        Articles on this site may include embedded content (e.g. videos, images,
        articles, etc.). Embedded content from other websites behaves in the
        exact same way as if the visitor has visited the other website.
      </Typography>
      <Typography variant="body">
        These websites may collect data about you, use cookies, embed additional
        third-party tracking, and monitor your interaction with that embedded
        content, including tracing your interaction with the embedded content if
        you have an account and are logged in to that website.
      </Typography>
      <Typography variant="h3">Analytics</Typography>
      <Typography variant="body">
        As you use this Website, we use automatic data collection technology
        (Google Analytics) to collect certain information about your device,
        browsing actions, and patterns. This generally includes information
        about where you are, how you use our website, and any communications
        between your computer and this site. Among other things, we will collect
        data about the type of computer you use, your Internet connection, your
        IP address, your operating system, and your browser type.
      </Typography>
      <Typography variant="body">
        We collect this data for statistical purposes and we don’t collect
        personal information. The purpose of this data is to improve our Website
        and offerings.
      </Typography>
      <Typography variant="body">
        If you wish to opt-out of Google Analytics so that none of your personal
        information is collected and stored by Google Analytics, you can
        download and install the Google Analytics Opt-out Browser Add-on here.
        For more information on how Google collects and uses your data, you can
        access the Google Privacy Policy here.
      </Typography>
      <Typography variant="h3">Who We Share Your Data With</Typography>
      <Typography variant="body">
        We don’t sell or share your data with anyone. We do use a 3rd party
        email provider to store your email address if you voluntarily signed up
        for our email newsletter. The privacy policy for SendInBlue can be found
        here.
      </Typography>
      <Typography variant="h3">How Long We Retain Your Data</Typography>
      <Typography variant="body">
        If you leave a comment, the comment and its metadata are retained
        indefinitely. This is so we can recognize and approve any follow-up
        comments automatically instead of holding them in a moderation queue.
      </Typography>
      <Typography variant="body">
        For users that register on our website (if any), we also store the
        personal information they provide in their user profile. All users can
        see, edit, or delete their personal information at any time (except they
        cannot change their username). Website administrators can also see and
        edit that information.
      </Typography>
      <Typography variant="h3">What Rights You Have Over Your Data</Typography>
      <Typography variant="body">
        If you have an account on this site, or have left comments, you can
        request to receive an exported file of the personal data we hold about
        you, including any data you have provided to us. You can also request
        that we erase any personal data we hold about you. This does not include
        any data we are obliged to keep for administrative, legal, or security
        purposes.
      </Typography>
      <Typography variant="h3">Where We Send Your Data</Typography>
      <Typography variant="body">
        Visitor comments may be checked through an automated spam detection
        service.
      </Typography>

      <Typography variant="h3">Our Contact Information</Typography>
      <Typography variant="body">
        {`For questions regarding your data and how we use it, you can reach us at
        info@${info.domain}. You can also email us here if you would like an
        extract of all of your personal information stored on our website or to
        request that we delete all of your information stored on our website.`}
      </Typography>

      <Typography variant="h3">How We Protect Your Data</Typography>
      <Typography variant="body">
        {`${info.title} uses secure third-party software such as MemberPress,
        SendOwl, Stripe, and PayPal for all financial transactions. No financial
        data is collected or stored by ${info.title}. Their privacy policies can
        be accessed at the links above.`}
      </Typography>
    </>
  );
};

export default PrivacyPolicyPage;
